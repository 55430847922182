//@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import "bulma/bulma";
@import "bulma-calendar/dist/css/bulma-calendar.min.css";
$primaryColor : #3e8ed0;

div, input, span, label, select, h1, h2, h3, h4, h5, p{
  //font-family: 'PT Sans', sans-serif;
}
video::-webkit-media-controls-timeline {
  display: none;
}

//scrollbar styling
::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px
}

select{
  border-radius: 0 !important;
}

.has-vertically-centered{
  vertical-align: middle;
}

.datetimepicker .datepicker-nav,
{
  background: $primaryColor;
}

.datetimepicker .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-highlighted {
  border: .1rem solid $primaryColor;
}

.datetimepicker .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-today {
  background: 0 0;
  border: .1rem dotted $primaryColor;
  color: $primaryColor;
}
.datetimepicker .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-active {
  background: $primaryColor;
  border-color: $primaryColor;
  border-radius: 25091983px;
  color: #fff;
}
